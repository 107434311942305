import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { IEmployeePositioning, ITimeFrameItem } from "../../types";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { ActionButton, FlexBox } from "../../styles";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Badge";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import ColorIcon from "@mui/icons-material/Circle";
import { useSelector } from "react-redux";
import {
  categoriesSelector,
  employeesSelector,
  locationsDataSelector,
} from "../../redux/orquest/selectors";
import { renderTimeViewClock, TimePicker } from "@mui/x-date-pickers";
import { addDays, format } from "date-fns";
import { useDispatch } from "react-redux";
import { updatePositioningRequested } from "../../redux/orquest/actions";
import { Dispatch } from "react";
import { getTimeFramesRange } from "../../helpers/functions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const smIcon: SxProps = {
  width: 20,
  height: 20,
  mr: 1,
  pb: 0.15,
};

const titleStyle: SxProps = {
  textAlign: "center",
  color: "primary",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const texfieldStyle: SxProps<Theme> = (theme) => ({
  "& .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
    color: theme.palette.mode === "dark" ? "#FFF" : "#000",
    WebkitTextFillColor: theme.palette.mode === "dark" ? "#FFF" : "#000",
    // "-webkit-text-fill-color": theme.palette.mode === "dark" ? "#FFF" : "#000",
  },
  "& .css-14jik6a-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
    color: theme.palette.mode === "dark" ? "#FFF" : "#000",
    WebkitTextFillColor: theme.palette.mode === "dark" ? "#FFF" : "#000",
    // "-webkit-text-fill-color": theme.palette.mode === "dark" ? "#FFF" : "#000",
  },
});

const defaultLocation = {
  locationId: -1,
  categoryId: -1,
  name: "",
  shortName: "",
  category: "",
  color: "",
};

function checkData(
  formData: ITimeFrameItem[],
  categoriesId: (number | string)[],
  startDates: (Date | null)[],
  endDates: (Date | null)[]
) {
  let result = false;
  formData.forEach((e, i) => {
    if (e.location.locationId === -1 || categoriesId[i] === -1 || !startDates[i] || !endDates[i])
      result = true;
  });

  return result;
}

interface Props {
  data?: IEmployeePositioning;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const EmployeePositioningFormModal = ({ data, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const employees = useSelector(employeesSelector);
  const locations = useSelector(locationsDataSelector);
  const categories = useSelector(categoriesSelector);

  const [personId, setPersonId] = React.useState<number | string>();
  const [categoriesId, setCategoriesId] = React.useState<number[]>([]);
  const [worked, setWorked] = React.useState(false);
  const [startDates, setStartDates] = React.useState<(Date | null)[]>([]);
  const [endDates, setEndDates] = React.useState<(Date | null)[]>([]);
  const [formData, setFormData] = React.useState<ITimeFrameItem[]>([]);

  const [error, setError] = React.useState<string>();
  const isEdit = Boolean(data);
  const disableSubmit = checkData(formData, categoriesId, startDates, endDates);

  React.useEffect(() => {
    if (open && data) {
      const newFormData: ITimeFrameItem[] = [];
      const newCategoriesId: number[] = [];
      const newStartDates: (Date | null)[] = [];
      const newEndDates: (Date | null)[] = [];
      data.presence?.timeFrames?.forEach((e) => {
        newCategoriesId.push(e.location.categoryId);
        newFormData.push({ ...e });
        newStartDates.push(new Date(e.startDay));
        newEndDates.push(new Date(e.endDay));
      });
      setPersonId(data.personId);
      setCategoriesId(newCategoriesId);
      setWorked(data.presence.worked);
      setStartDates(newStartDates);
      setEndDates(newEndDates);
      setFormData(newFormData);
    }
  }, [data, open]);

  const handleCategory = (index: number, value: string) => {
    /** categories */
    let newCategoriesId = [...categoriesId];
    newCategoriesId[index] = parseInt(value);
    setCategoriesId(newCategoriesId);
    /** formData */
    let newFormData = [...formData];
    newFormData[index].location = { ...defaultLocation };
    /** dates */
    setStartDates((state) => {
      state[index] = null;
      return state;
    });
    setEndDates((state) => {
      state[index] = null;
      return state;
    });
  };

  const handleLocation = (index: number, value: string) => {
    /** form */
    let newData = [...formData];
    const location = locations.find((e) => e.locationId === parseInt(value));
    newData[index].location = { ...location! };
    setFormData(newData);
    /** dates */
    setStartDates((state) => {
      state[index] = null;
      return state;
    });
    setEndDates((state) => {
      state[index] = null;
      return state;
    });

    setError(undefined);
  };

  const handleTime = (index: number, field: string, value: Date) => {
    let newData = [...formData];
    let newValue = `${data?.businessDay} ${format(value, "HH:mm")}`;
    let newDate = new Date(newValue);

    if (field === "startDay") {
      newData[index].startDay = newValue;
      setStartDates((state) => {
        state[index] = value;
        return state;
      });
      setEndDates((state) => {
        state[index] = null;
        return state;
      });
    }
    if (field === "endDay") {
      const startDate = startDates[index];
      if (startDate && value.getHours() < startDate.getHours()) {
        newDate = addDays(newDate, 1);
        newValue = format(newDate, "yyyy-MM-dd HH:mm");
      }
      newData[index].endDay = newValue;
      setEndDates((state) => {
        state[index] = newDate;
        return state;
      });
    }

    setFormData(newData);
    setError(undefined);
  };

  const handleNewItem = () => {
    /** form */
    const newData = [...formData];
    const newItem: ITimeFrameItem = {
      frameId: -1,
      startDay: "",
      endDay: "",
      location: { ...defaultLocation },
    };
    newData.push(newItem);
    setFormData(newData);
    if (!worked) setWorked(true);
    /** categories */
    setCategoriesId((prev) => {
      prev.push(-1);
      return prev;
    });
    /** dates */
    setStartDates((prev) => {
      prev.push(null);
      return prev;
    });
    setEndDates((prev) => {
      prev.push(null);
      return prev;
    });
  };

  const handleDelete = (index: number) => {
    /** form */
    let newData = [...formData];
    newData.splice(index, 1);
    setFormData(newData);
    if (newData.length === 0) setWorked(false);
    /** categories */
    setCategoriesId((prev) => {
      prev.splice(index, 1);
      return prev;
    });
    /** dates */
    setStartDates((prev) => {
      prev.splice(index, 1);
      return prev;
    });
    setEndDates((prev) => {
      prev.splice(index, 1);
      return prev;
    });
  };

  const handleWorked = (value: boolean) => {
    setWorked(value);
    if (!value) {
      setCategoriesId([]);
      setStartDates([]);
      setEndDates([]);
      setFormData([]);
    }
  };

  const handleReset = () => {
    setPersonId(undefined);
    setCategoriesId([]);
    setStartDates([]);
    setEndDates([]);
    setFormData([]);
    setError(undefined);
  };

  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const handleSubmit = () => {
    if (data) {
      let newData: IEmployeePositioning = { ...data };
      newData.presence.worked = worked;
      newData.presence.timeFrames = formData;
      newData.timeRange = getTimeFramesRange(formData);
      dispatch(updatePositioningRequested(newData));
    }
    handleClose();
  };

  /** RENDERS */

  const renderContent = () => {
    return (
      <>
        <DialogTitle sx={titleStyle}>
          <Box flexGrow={1} sx={isEdit ? { ml: 3 } : null}>
            {t("pages.positioning.formTitle")}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="description">
            {t("pages.positioning.newPositioning")}
          </DialogContentText>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={5}>
              <TextField
                error={Boolean(error)}
                fullWidth
                id="personName"
                select
                label={t("labels.employee")}
                size="small"
                value={personId || ""}
                disabled={isEdit}
                onChange={(e) => setPersonId(e.target.value)}
              >
                {employees.map((o, index) => (
                  <MenuItem key={index} value={o.personId}>
                    {o.personName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={7}>
              <FormControlLabel
                control={
                  <Checkbox onChange={(e, checked) => handleWorked(!checked)} checked={!worked} />
                }
                label={t("labels.noWorked")}
              />
            </Grid>
            <Grid item xs={12}>
              <FlexBox sx={{ justifyContent: "space-between", my: 1 }}>
                <Typography variant="h6" mr={1}>
                  {t("labels.positioning")}
                </Typography>
                <ActionButton
                  variant="outlined"
                  startIcon={<AddIcon />}
                  sx={{ color: (theme) => theme.palette.text.primary, px: 2 }}
                  onClick={handleNewItem}
                >
                  {t("labels.add")}
                </ActionButton>
              </FlexBox>
              <Divider flexItem />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            mt={1}
            sx={{ overflowY: "scroll", maxHeight: "calc(100vh - 450px)" }}
          >
            {!personId && (
              <Grid item xs={12}>
                {[1, 2, 3].map((e) => (
                  <FlexBox key={e} sx={{ justifyContent: "space-between" }}>
                    <Skeleton animation="wave" height={40} width={"40%"} />
                    <Skeleton animation="wave" height={40} width={"25%"} />
                    <Skeleton animation="wave" height={40} width={"25%"} />
                    <Skeleton animation="wave" height={40} width={"5%"} />
                  </FlexBox>
                ))}
              </Grid>
            )}
            {formData.map((item, index) => (
              <React.Fragment key={`${index}-${item.frameId}`}>
                <Grid item xs={3.5}>
                  <TextField
                    error={Boolean(error)}
                    fullWidth
                    id="category"
                    select
                    label={t("labels.category")}
                    size="small"
                    value={categoriesId[index] || ""}
                    onChange={(e) => handleCategory(index, e.target.value)}
                  >
                    {categories.map((o, index) => (
                      <MenuItem key={index} value={o.categoryId}>
                        <Typography>{o.category}</Typography>
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4.5}>
                  <TextField
                    error={Boolean(error)}
                    fullWidth
                    id="name"
                    select
                    label={t("labels.position")}
                    size="small"
                    value={formData[index] ? formData[index].location.locationId : ""}
                    disabled={!categoriesId[index] || categoriesId[index] === -1}
                    onChange={(e) => handleLocation(index, e.target.value)}
                  >
                    {locations
                      .filter((e) => e.categoryId === categoriesId[index])
                      .map((o, index) => (
                        <MenuItem key={index} value={o.locationId}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ColorIcon sx={{ ...smIcon, color: o.color || "primary.main" }} />
                            <Typography>{o.name}</Typography>
                          </Box>
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={1.5}>
                  <TimePicker
                    label={t("labels.startDay")}
                    ampm={false}
                    minutesStep={5}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                    }}
                    slotProps={{ textField: { size: "small", disabled: true, sx: texfieldStyle } }}
                    value={startDates[index] || null}
                    disabled={
                      formData[index] === null || formData[index]?.location?.locationId === -1
                    }
                    onChange={(newValue) => newValue && handleTime(index, "startDay", newValue)}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <TimePicker
                    label={t("labels.endDay")}
                    ampm={false}
                    minutesStep={5}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                    }}
                    slotProps={{ textField: { size: "small", disabled: true, sx: texfieldStyle } }}
                    value={endDates[index] || null}
                    disabled={startDates[index] === null}
                    onChange={(newValue) => newValue && handleTime(index, "endDay", newValue)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton size="small" onClick={() => handleDelete(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </DialogContent>
      </>
    );
  };

  const renderError = () => {
    if (error)
      return (
        <FlexBox ml={3}>
          <ErrorIcon color="error" sx={{ width: 15, height: 15 }} />
          <Typography variant="body2" color="error" ml={0.5}>
            {error}
          </Typography>
        </FlexBox>
      );
  };

  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-describedby="description"
      maxWidth={"lg"}
      keepMounted={false}
    >
      <Box p={1}>
        {renderContent()}
        {renderError()}
        <DialogActions sx={{ mx: 2 }}>
          <ActionButton
            variant="outlined"
            onClick={handleClose}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            {t("labels.cancel")}
          </ActionButton>
          <ActionButton variant="contained" onClick={handleSubmit} disabled={disableSubmit}>
            {t("labels.confirm")}
          </ActionButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EmployeePositioningFormModal;
