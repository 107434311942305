import PedalBike from "@mui/icons-material/PedalBike";
import SoupKitchen from "@mui/icons-material/SoupKitchen";
import Fastfood from "@mui/icons-material/Fastfood";
import DirectionsCar from "@mui/icons-material/DirectionsCar";
import LocalCafe from "@mui/icons-material/LocalCafe";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import Engineering from "@mui/icons-material/Engineering";
import Deck from "@mui/icons-material/Deck";
import Storefront from "@mui/icons-material/Storefront";
import Icecream from "@mui/icons-material/Icecream";
import OtherIcon from "@mui/icons-material/Circle";
import { SxProps } from "@mui/material";

const xsIcon: SxProps = {
  height: 12,
  width: 12,
  mt: 0.25,
  ml: 0.3,
};

export const LOCATION_CATEGORY_ICONS: { [key: number]: JSX.Element } = {
  1: <PedalBike />, // DELIVERY
  2: <SoupKitchen />, // COCINA
  3: <Fastfood />, // MOSTRADOR
  4: <LocalCafe />, // MCCAFE
  5: <DirectionsCar />, // AUTOMAC
  6: <Deck />, // "ENTREGA A LA MESA
  7: <SupervisorAccount />, // GERENTE
  8: <Engineering />, // TAREAS GENERALES
  9: <Storefront />, // CENTRO DE POSTRES
  10: <Icecream />, // KIOSCO & ISLA
};

export function getLocationCategoryIcon(key?: number) {
  if (!key || !LOCATION_CATEGORY_ICONS[key]) return <OtherIcon sx={xsIcon} />;
  else return LOCATION_CATEGORY_ICONS[key];
}
