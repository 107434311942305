import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { useSelector } from "react-redux";
import { scheduleRangeSelector } from "../../redux/admin/schedules/selectors";
import { IEmployeePositioning } from "../../types";
import TimeFrame from "./TimeFrame";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { BoldTypography, FlexBox } from "../../styles";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import EmployeePositioningFormModal from "../../components/modals/EmployeePositioningFormModal";
import { isLoadingSelector, positioningDataSelector } from "../../redux/orquest/selectors";
import {
  compareEmployess,
  compareLocations,
  formattedMTRData,
  getMRTLocalization,
} from "../../helpers/functions";
import { getLocationCategoryIcon } from "./utils";

const ignore = ["BREAK"];

const MRTable = () => {
  const { t, i18n } = useTranslation();
  const positioningData = useSelector(positioningDataSelector);
  const scheduleRange = useSelector(scheduleRangeSelector);
  const positioningLoading = useSelector(isLoadingSelector);
  const [positioning, setPositioning] = useState<IEmployeePositioning[]>([]);
  const [unassignedEmployees, setUnassignedEmployees] = useState<IEmployeePositioning[]>([]);
  const [openForm, setOpenForm] = useState(false);
  const [view, setView] = useState<string>("category");
  const [selectedRow, setSelectedRow] = useState<IEmployeePositioning>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      const result: IEmployeePositioning[] = [];
      const dataByCategories = formattedMTRData(positioningData);

      Object.keys(dataByCategories).forEach((key) => {
        if (!ignore.includes(key)) {
          const rowData =
            dataByCategories[key] && dataByCategories[key][0] ? dataByCategories[key][0] : null;
          const row: Partial<IEmployeePositioning> = {
            name: key,
            categoryId: rowData ? rowData.categoryId : -1,
            category: rowData ? rowData.category : "",
            subRows: [...dataByCategories[key]].sort(compareEmployess),
          };
          result.push(row as IEmployeePositioning);
        }
      });

      setPositioning(result.sort(compareLocations));
      setUnassignedEmployees(
        positioningData.filter((e) => !e.presence?.worked).sort(compareEmployess)
      );
      setIsLoading(false);
    }, 500);
  }, [positioningData]);

  const handleRowClick = (row: IEmployeePositioning) => {
    if (row.personName) {
      const presence = {
        ...row.presence,
        timeFrames: row.presence.timeFrames.map((e) => ({ ...e })),
      };
      const employee: IEmployeePositioning = { ...row, presence };
      setSelectedRow(employee);
      setOpenForm(true);
    }
  };

  const handleView = (event: React.ChangeEvent<HTMLInputElement>) => {
    setView((event.target as HTMLInputElement).value);
  };

  const handleViewData = () => {
    switch (view) {
      case "category":
        return positioning;
      case "employee":
        return positioningData.sort(compareEmployess);
      case "no-assignment":
        return unassignedEmployees;

      default:
        return [];
    }
  };

  const columns = useMemo<MRT_ColumnDef<IEmployeePositioning>[]>(() => {
    const result: MRT_ColumnDef<IEmployeePositioning>[] = [
      {
        accessorKey: "name",
        header: "",
        size: 10,
        Cell: ({ row }) =>
          row.original.personName ? (
            <Typography variant="caption">{row.original.personName}</Typography>
          ) : (
            <FlexBox>
              {getLocationCategoryIcon(row.original.categoryId)}
              <BoldTypography
                variant="body2"
                sx={{ ml: 1, mt: 0.25 }}
              >{`${row.original.name}`}</BoldTypography>
            </FlexBox>
          ),
      },
      {
        accessorKey: "personName",
        header: "",
        size: 10,
        enableHiding: true,
        Cell: ({ row }) => <Typography variant="caption">{row.original.personName}</Typography>,
      },
      {
        accessorKey: "timeRange",
        header: "",
        size: 10,
        Cell: ({ cell }) => <Typography variant="caption">{cell.getValue<string>()}</Typography>,
      },
      {
        accessorKey: "dummy",
        header: "",
        size: 0,
      },
    ];

    scheduleRange.forEach((time, index) => {
      const column: MRT_ColumnDef<IEmployeePositioning> = {
        accessorKey: `${time}`,
        header: `00`,
        Header: () => <Box sx={{ width: "100%", textAlign: "center" }}>{`${time}:00`}</Box>,
        size: 10,
        muiTableHeadCellProps: {
          sx: {
            textAlign: "center",
            justifyContent: "center",
            fontWeight: 300,
            "& .Mui-TableHeadCell-Content": {
              justifyContent: "center",
            },
            // borderLeft: '0.5px solid rgba(0, 0, 0, .3)',
          },
        },
        muiTableBodyCellProps: {
          sx: {
            padding: 0,
            margin: 0,
            zIndex: 100 - index,
            overflow: "visible",
            // borderLeft: '0.5px solid rgba(0, 0, 0, .3)',
          },
        },
        Cell: ({ cell, row }) => (
          <TimeFrame
            time={time}
            timeFrames={row?.original?.presence?.timeFrames || []}
            category={row?.original?.name || ""}
            businessDay={row?.original?.businessDay || ""}
            showMainColors={view === "employee"}
          />
        ),
      };
      result.push(column);
    });

    return result;
  }, [scheduleRange, view]);

  const table = useMaterialReactTable({
    columns,
    data: handleViewData(),
    enableStickyHeader: true,
    enableExpandAll: true,
    enableExpanding: true,
    filterFromLeafRows: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: false,
    enableDensityToggle: false,
    enableColumnOrdering: false,
    getSubRows: (row) => row.subRows,
    initialState: {
      expanded: true,
      density: "compact",
      pagination: { pageSize: 20, pageIndex: 0 },
    },
    paginateExpandedRows: false,
    localization: getMRTLocalization(i18n.language),
    // state
    state: {
      isLoading: isLoading || positioningLoading,
      columnVisibility: { personName: false },
    },
    // actions
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => handleRowClick(row.original),
      sx: {
        cursor: "pointer",
        bgcolor: (theme) =>
          !row.original.personName
            ? theme.palette.mode === "dark"
              ? "#2b2b2b"
              : grey[100]
            : "inherit",
      },
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="radio-buttons-group"
            name="row-radio-buttons-group"
            value={view}
            onChange={handleView}
          >
            <FormControlLabel
              value="category"
              control={<Radio />}
              label={t("labels.positioning")}
            />
            <FormControlLabel value="employee" control={<Radio />} label={t("labels.employees")} />
            <FormControlLabel
              value="no-assignment"
              control={<Radio />}
              label={t("labels.noAssignment")}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    ),
    // styles
    muiTableContainerProps: { sx: { maxHeight: "calc(100vh - 234px)" } },
    displayColumnDefOptions: {
      "mrt-row-expand": {
        header: "",
        size: 10,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        padding: 0,
        margin: 0,
      },
    },
    muiTableBodyCellProps: {
      sx: {
        padding: 0,
        margin: 0,
      },
    },
    muiExpandButtonProps: ({ row }) => ({
      sx: { display: row.original.personName ? "none" : undefined },
    }),
    muiSearchTextFieldProps: {
      placeholder:
        view === "category"
          ? t("labels.searchByPosition").toString()
          : t("labels.searchByEmployee").toString(),
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <EmployeePositioningFormModal data={selectedRow} open={openForm} setOpen={setOpenForm} />
    </>
  );
};

export default MRTable;
