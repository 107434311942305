import { compareTimeFrames, getTimeFramesRange } from "../../helpers/functions";
import { IEmployeeDetails, IEmployeeLocation, IEmployeePositioning } from "../../types";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../utils";
import { Actions, ActionType, OrquestState } from "./types";

function handlePositioningData(positioningData: IEmployeePositioning[]) {
  const employees: IEmployeeDetails[] = [];
  const positioning: IEmployeePositioning[] = [];
  try {
    positioningData.forEach((e) => {
      const presence = {
        worked: e.presence && e.presence.worked ? e.presence.worked : false,
        timeFrames:
          e.presence && e.presence.timeFrames ? e.presence.timeFrames.sort(compareTimeFrames) : [],
      };
      employees.push({ personId: e.personId, personName: e.personName });
      positioning.push({ ...e, presence, timeRange: getTimeFramesRange(presence.timeFrames) });
    });
  } catch (error) {
    console.log(error);
  }
  return { positioning: doneRemoteResource(positioning), employees };
}

function updatePositioning(positioning: IEmployeePositioning[], data: IEmployeePositioning) {
  let newPositioning = [...positioning];
  const index = newPositioning.findIndex((e) => e.personId === data.personId);
  if (index !== -1) {
    newPositioning[index] = data;
  }
  return doneRemoteResource(newPositioning);
}

function getCategories(locations: IEmployeeLocation[]) {
  const categories: IEmployeeLocation[] = [];
  locations.forEach((l) => {
    const exist = Boolean(categories.find((e) => e.categoryId === l.categoryId));
    if (!exist) categories.push({ ...l });
  });

  return categories;
}

const initialState: OrquestState = {
  positioning: initialRemoteResource([]),
  employees: [],
  locations: initialRemoteResource([]),
  categories: [],
  isLoading: false,
  isUpdating: false,
};

const orquestReducer = (state: OrquestState = initialState, action: Actions): OrquestState => {
  switch (action.type) {
    case ActionType.getPositioningRequested:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.getPositioningDone:
      return {
        ...state,
        ...handlePositioningData(action.payload.positioning),
        isLoading: false,
      };
    case ActionType.getPositioningError:
      return {
        ...state,
        positioning: errorRemoteResource([]),
        employees: [],
        isLoading: false,
      };

    case ActionType.updatePositioningRequested:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.updatePositioningDone:
      return {
        ...state,
        positioning: updatePositioning(state.positioning.data, action.payload.positioning),
        isLoading: false,
      };
    case ActionType.updatePositioningError:
      return {
        ...state,
        isLoading: false,
      };

    case ActionType.getLocationsRequested:
      return {
        ...state,
        locations: requestedRemoteResource([]),
        categories: [],
        isLoading: true,
      };
    case ActionType.getLocationsDone:
      return {
        ...state,
        locations: doneRemoteResource(action.payload.locations),
        categories: getCategories(action.payload.locations),
        isLoading: false,
      };
    case ActionType.getLocationsError:
      return {
        ...state,
        locations: errorRemoteResource([]),
        categories: [],
        isLoading: false,
      };

    default:
      return state;
  }
};

export default orquestReducer;
