import React from "react";
import { useMemo } from "react";
import { ITimeFrameItem } from "../../types";
import { Box, styled, Typography } from "@mui/material";
import { addDays, isAfter, isBefore, isSameHour, subHours } from "date-fns";
import { useSelector } from "react-redux";
import { scheduleRangeSelector } from "../../redux/admin/schedules/selectors";
import { FlexBox } from "../../styles";

interface ITimeRange {
  range: string;
  sameCategory: boolean;
  shortName: string;
  width: string | number;
  color: string;
}

const defaultTR: ITimeRange = {
  range: "null",
  sameCategory: false,
  shortName: "",
  width: "100%",
  color: "primary.main",
};

function getWidthPercent(minutes: number) {
  if (!minutes || minutes === 0) return "100%";
  else {
    const value = (minutes * 99) / 59;

    return `${Math.floor(value)}%`;
    // return (value / 100) * 48;
  }
}

function getTimeRange(
  time: number,
  timeFrames: ITimeFrameItem[],
  category: string,
  businessDay: string,
  scheduleRange: number[]
): ITimeRange[] {
  let result: ITimeRange[] = [{ ...defaultTR }];
  let timeToDate = new Date(`${businessDay} ${time}:00`);
  const startHour = scheduleRange[0];
  const endHour = scheduleRange[scheduleRange.length - 1];

  if (endHour < startHour && time < startHour) timeToDate = addDays(timeToDate, 1);

  let index = 0;
  timeFrames.forEach((item) => {
    const startDate = new Date(item.startDay);
    const endDate = new Date(item.endDay);
    const subEndDate = subHours(new Date(item.endDay), 1);
    const startDateMinutes = startDate.getMinutes();
    const endDateMinutes = endDate.getMinutes();

    /** check time-frame */

    if (isSameHour(timeToDate, startDate)) {
      if (
        isSameHour(startDate, endDate) ||
        (endDateMinutes === 0 && isSameHour(startDate, subEndDate))
      ) {
        result[index].range = "start-end";
        result[index].width =
          startDateMinutes > 0
            ? getWidthPercent(startDateMinutes)
            : endDateMinutes > 0
            ? getWidthPercent(endDateMinutes)
            : "100%";
      } else {
        result[index].range = "start";
        result[index].width = getWidthPercent(startDateMinutes);
      }
    } else if (
      (endDateMinutes === 0 && isSameHour(timeToDate, subEndDate)) ||
      (endDateMinutes > 0 && isSameHour(timeToDate, endDate))
    ) {
      result[index].range = "end";
      result[index].width = getWidthPercent(endDateMinutes);
    } else if (isAfter(timeToDate, startDate) && isBefore(timeToDate, subEndDate)) {
      result[index].range = "middle";
    }

    /** updade result */

    if (result[index].range !== "null") {
      result[index].sameCategory = item.location.name === category;
      result[index].shortName = item.location.shortName;
      result[index].color = item.location?.color?.includes("#")
        ? item.location.color
        : "primary.main";
      result.push({ ...defaultTR });
      index++;
    }
  });

  return result;
}

function getOrientation(timeRange: ITimeRange[]) {
  const tr1 = timeRange[0];
  const tr2 = timeRange[1];
  if (
    tr1 &&
    tr1.range !== "null" &&
    tr1.width !== "100%" &&
    tr1.range === "start" &&
    (!tr2 || tr2.range === "null")
  ) {
    return "end";
  } else return undefined;
}

interface Props {
  time: number;
  timeFrames: ITimeFrameItem[];
  category: string;
  businessDay: string;
  showMainColors?: boolean;
}

const TimeFrame = ({ time, timeFrames, category, businessDay, showMainColors }: Props) => {
  const scheduleRange = useSelector(scheduleRangeSelector);

  const timeRange: ITimeRange[] = useMemo(() => {
    return getTimeRange(time, timeFrames, category, businessDay, scheduleRange);
  }, [time, timeFrames, category, businessDay, scheduleRange]);

  return (
    <FlexBox sx={{ my: 0.3, justifyContent: getOrientation(timeRange) }}>
      {timeRange.map((e, index) => {
        if (e.range === "null") return null;

        const width = e.width;
        const bgcolor = e.color;
        const opacity = e.sameCategory || showMainColors ? 1 : 0.25;

        switch (e.range) {
          case "start-end":
            return (
              <TimeFrameBox
                key={`${index}-${e.range}`}
                sx={{ borderRadius: "50px 50px 50px 50px", bgcolor, opacity, width }}
              >
                <ShortNameText sx={{ overflow: "hidden" }}>
                  {e.shortName.substring(0, 3)}
                </ShortNameText>
              </TimeFrameBox>
            );
          case "start":
            return (
              <TimeFrameBox
                key={`${index}-${e.range}`}
                sx={{
                  borderRadius: "50px 0px 0px 50px",
                  bgcolor,
                  opacity,
                  width,
                  justifySelf: "end",
                }}
              >
                <ShortNameText>{e.shortName.substring(0, 3)}</ShortNameText>
              </TimeFrameBox>
            );
          case "end":
            return (
              <TimeFrameBox
                key={`${index}-${e.range}`}
                sx={{
                  borderRadius: "0px 50px 50px 0px",
                  bgcolor,
                  opacity,
                  width,
                }}
              />
            );
          case "middle":
            return <TimeFrameBox key={`${index}-${e.range}`} sx={{ bgcolor, opacity }} />;
          default:
            return <TimeFrameBox key={`${index}-${e.range}`} />;
        }
      })}
    </FlexBox>
  );
};

export default TimeFrame;

const TimeFrameBox = styled(Box)({
  position: "relative",
  height: 20,
  width: "100%",
  alignContent: "center",
});

const ShortNameText = styled(Typography)({
  color: "#FFF",
  paddingInline: 4,
  zIndex: 100,
  fontSize: "0.7rem",
});
