import {
  endOfDay,
  format,
  intervalToDuration,
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
} from "date-fns";
import { t } from "i18next";
import i18n from "../config/i18n.config";
import {
  IShiftSchedules,
  IScheduleItem,
  IAreaType,
  IStore,
  IMasterdata,
  ICountry,
  IAppFilters,
  IQueryParams,
  IActivityTypeItem,
  IActivityTypes,
  IActivityItem,
  IAreaChecklist,
  IObjectiveItem,
  IObjectiveTypeItem,
  IManager,
  IHourlyReviewStep,
  IGenericDescription,
  IHourlyReviewStepsList,
  INoteItem,
  ISegmentItem,
  IChangelogItemDescription,
  IChangelogItem,
  IEmployeePositioning,
  ITimeFrameItem,
} from "../types";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";
import fr from "date-fns/locale/fr";
import { ACTIVIY_TYPES_ROUTE } from "./constants";
import { FormattedHourlyReview } from "../pages/activities/HourlyReview";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import { MRT_Localization_FR } from "material-react-table/locales/fr";

export interface PromiseResult<TData = any> {
  result?: TData;
  hasError?: boolean;
  error?: any;
}

export const promiseResultWrapper = async <TData = any>(
  executor: () => Promise<TData>
): Promise<PromiseResult<TData>> => {
  return executor()
    .then((data) => ({ result: data }))
    .catch((e) => ({ hasError: true, error: e }));
};

/** formatters */

export function getFormattedMasterdata(masterDataResponse: IStore[]) {
  const masterData: IMasterdata = {
    divisions: getDivisions(masterDataResponse),
    countries: getCountries(masterDataResponse),
    stores: masterDataResponse.sort(compareStores),
  };
  return masterData;
}

export const getLocale = () => {
  switch (i18n.language) {
    case "en":
      return undefined;
    case "fr":
      return fr;
    case "pt":
      return pt;
    default:
      return es;
  }
};

export function numberFormatter(value: number, digits?: number, decimals?: number) {
  return value.toLocaleString(i18n.language, {
    minimumIntegerDigits: digits,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export function shiftHourFormatted(value: number) {
  if (value > 9) return `${value}00`;
  else return `0${value}00`;
}

export function getISOString(date?: Date) {
  const now = date ? date : new Date();
  return now.toISOString();
}

export function dateFormat() {
  if (i18n.language === "es-US") return "dd/MM/yyyy HH:mm";
  return "yyyy/MM/dd HH:mm";
}

export function timeLabelToInt(time: string) {
  if (!time) return null;

  const values = time.split(":");
  if (values[0] && values[0] !== "") return parseInt(values[0]);
  else return null;
}

export function timeIntToLabel(value: number) {
  if (value > 9) return `${value}:00`;
  else return `0${value}:00`;
}

export function stringAvatar(user?: IManager) {
  if (user) {
    const { name, lastname } = user;
    return `${name[0]?.toUpperCase() || ""}${lastname[0]?.toUpperCase() || ""}`;
  }
}

export function timeFormat(seconds: number, inWords?: boolean) {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  if (inWords) {
    const hrsInWords = `${duration.hours || "0"} ${
      duration.hours && duration.hours > 1 ? "hrs" : "hr"
    }`;
    const minInWords = `${duration.minutes || "0"} min`;
    const secInWords = `${duration.seconds || "0"} ${t("labels.seconds").toLowerCase()}`;
    return `${hrsInWords} ${minInWords} ${secInWords}`;
  }
  const hrs = duration.hours ? numberFormatter(duration.hours, 2) : "00";
  const min = duration.minutes ? numberFormatter(duration.minutes, 2) : "00";
  const sec = duration.seconds ? numberFormatter(duration.seconds, 2) : "00";
  return `${hrs}:${min}:${sec}`;
}

export function activityTypesFormatted(data: IActivityTypeItem[]): IActivityTypes {
  const result: IActivityTypes = {};
  data.forEach((item) => (result[item.id] = { ...item }));
  return result;
}

export function scheduleResponseFormatter(data: IScheduleItem[]): IShiftSchedules {
  let result: IShiftSchedules = {};
  const sortOrder = ["O", "M", "C", "E"];
  data.forEach((item) => {
    const weekday = !item.weekday ? "monday" : item.weekday;
    if (!result[weekday]) result[weekday] = [];
    result[weekday].push({ ...item });
    result[weekday].sort((a, b) => {
      if (
        sortOrder.findIndex((i) => i === a.shiftTypeId) >
        sortOrder.findIndex((i) => i === b.shiftTypeId)
      )
        return 1;
      if (
        sortOrder.findIndex((i) => i === a.shiftTypeId) <
        sortOrder.findIndex((i) => i === b.shiftTypeId)
      )
        return -1;
      return 0;
    });
  });
  return result;
}

export function scheduleRawFormatter(data: IShiftSchedules): IScheduleItem[] {
  let result: IScheduleItem[] = [];
  Object.keys(data).forEach((key) => {
    data[key].forEach((e) => result.push({ ...e }));
  });
  return result;
}

export function areasChecklistFormatter(data: IActivityItem[]): IAreaChecklist {
  let result: IAreaChecklist = {};
  data.forEach((e) => {
    const key = e.AreaTypeId;
    if (!result[key]) result[key] = [];
    result[key].push(e);
  });
  return result;
}

export function areasChecklistRawFormatter(data: IAreaChecklist): IActivityItem[] {
  let result: IActivityItem[] = [];
  if (data)
    Object.keys(data).forEach((key) => {
      if (data[key]) data[key].forEach((e) => result.push({ ...e }));
    });

  return result;
}

export function stepsFormatted(steps: IHourlyReviewStep[]): IHourlyReviewStepsList[] {
  let result: IHourlyReviewStepsList[] = [];
  let map: { [key: string]: IHourlyReviewStep[] } = {};
  steps.forEach((e) => {
    const key = e.stepOrder.toString();
    if (!map[key]) map[key] = [];
    map[key].push(e);
  });
  Object.keys(map).forEach((key) => {
    const step = map[key];
    if (step[0]) {
      let item: IHourlyReviewStepsList = {
        stepOrder: step[0].stepOrder,
        focusDescription_en: step[0].focusDescription_en,
        focusDescription_es: step[0].focusDescription_es,
        focusDescription_pt: step[0].focusDescription_pt,
        focusDescription_fr: step[0].focusDescription_fr,
        groupDescription_en: step[0].groupDescription_en,
        groupDescription_es: step[0].groupDescription_es,
        groupDescription_pt: step[0].groupDescription_pt,
        groupDescription_fr: step[0].groupDescription_fr,
        descriptions: [],
      };
      step.forEach((e) => {
        const descriptions: IGenericDescription = {
          description_en: e.description_en,
          description_es: e.description_es,
          description_pt: e.description_pt,
          description_fr: e.description_fr,
        };
        item.descriptions.push(descriptions);
      });
      result.push(item);
    }
  });
  return result;
}

export function formattedDate(date?: string, pattern?: string) {
  if (date) return format(new Date(date), pattern || "PP", { locale: getLocale() });
  return "-";
}

export function formattedTime(shift?: IScheduleItem) {
  if (shift) return `${shift.shiftStart} - ${shift.shiftEnd}`;
  return "-";
}

export function formattedMTRData(data: IEmployeePositioning[]) {
  let result: { [key: string]: IEmployeePositioning[] } = {};

  data.forEach((employee) => {
    employee.presence.timeFrames.forEach((t) => {
      const name = t.location.name;
      const categoryId = t.location.categoryId;
      const category = t.location.category;
      if (!result[name]) result[name] = [];
      const hasEmployee = result[name].find((e) => e.personId === employee.personId);
      if (!hasEmployee)
        result[name].push({
          ...employee,
          name,
          categoryId,
          category,
          timeRange: getTimeFramesRange(employee.presence.timeFrames),
        });
    });
  });

  return result;
}

/** functions */

export function getQueryParams(params: IAppFilters): IQueryParams {
  const { division, country, store, businessDate, shiftTypeId, storePlanId, shift } = params;
  return {
    division,
    country: country?.label,
    storeAcronym: store?.storeAcronym,
    store: store?.storeId,
    businessDate,
    shiftTypeId,
    storePlanId,
    shiftStart: shift?.shiftStart,
    shiftEnd: shift?.shiftEnd,
  };
}

export function getManagersCompleted(managers: IManager[]) {
  let result = false;
  managers.forEach((m) => {
    if (m.active) {
      result = true;
      return;
    }
  });
  return result;
}

export function getAreasCompleted(areas: IAreaType[]) {
  let result = false;
  areas.forEach((a) => {
    if (a.areaTypeId !== "GRL" && a.areaEnabled) {
      result = true;
      return;
    }
  });
  return result;
}

export function getSchedulesCompleted(schedules: IShiftSchedules) {
  const haveSchedules = Object.keys(schedules)?.length > 4;
  const keys = Object.keys(schedules)?.map((key) => key) || [];
  let haveRushHours = false;

  for (let index = 0; index < keys.length && !haveRushHours; index++) {
    const key = keys[index];
    if (schedules[key].length > 4) {
      // eslint-disable-next-line
      schedules[key].every((e) => {
        if (e.shiftTypeId === "RH" && e.rushHours.length > 0) {
          haveRushHours = true;
          return false;
        } else return true;
      });
    }
  }

  return haveSchedules && haveRushHours;
}

export function getFrequency(shiftStart?: string, shiftEnd?: string) {
  const hour = new Date().getHours();
  if (shiftStart && shiftEnd) {
    const start = parseInt(shiftStart.split(":")[0]);
    const end = parseInt(shiftEnd.split(":")[0]);

    if (!isNaN(start) && !isNaN(end)) {
      const frequency: string[] = [shiftHourFormatted(start)];
      let current = start;
      while (current !== end) {
        if (current >= 23) current = 0;
        else current++;
        frequency.push(shiftHourFormatted(current));
      }
      return frequency.join();
    } else return shiftHourFormatted(hour);
  } else return shiftHourFormatted(hour);
}

export function getShiftHours(shiftStart?: string, shiftEnd?: string) {
  const hours: number[] = [];
  if (shiftStart && shiftEnd) {
    const start = parseInt(shiftStart.split(":")[0]);
    const end = parseInt(shiftEnd.split(":")[0]);
    if (!isNaN(start) && !isNaN(end)) {
      let current = start;
      hours.push(current);
      while (current !== end) {
        if (current >= 23) current = 0;
        else current++;
        hours.push(current);
      }
    }
  }
  return hours;
}

export function isBetweenDates(date: Date, from: Date, to: Date, disableEqual?: boolean) {
  const equal =
    isEqual(startOfDay(date), startOfDay(from)) || isEqual(endOfDay(date), endOfDay(to));
  const before = isBefore(date, endOfDay(to));
  const after = isAfter(date, startOfDay(from));

  if (disableEqual) return after && before;
  return equal || (after && before);
}

export function isBetweenShift(date: Date, businessDate?: string, shift?: IScheduleItem) {
  if (!shift || !businessDate || isAfter(startOfDay(new Date(businessDate)), startOfDay(date)))
    return false;

  if (isBefore(startOfDay(new Date(businessDate)), startOfDay(date))) return true;

  const shiftStart = timeLabelToInt(shift.shiftStart);
  const shiftEnd = timeLabelToInt(shift.shiftEnd);

  if (shiftStart !== null && shiftEnd !== null) {
    const hour = date.getHours();
    if (
      shiftEnd < shiftStart &&
      ((hour >= shiftStart && hour <= 23) || (hour >= 0 && hour <= shiftEnd))
    ) {
      return true;
    } else if (hour >= shiftStart && hour <= shiftEnd) {
      return true;
    }
  }

  return false;
}

export function getActivityTypeByRoute(route: string) {
  return ACTIVIY_TYPES_ROUTE[route];
}

export function getDivisions(masterDataResponse: IStore[]) {
  const divisions: string[] = [];
  masterDataResponse.forEach((s) => {
    if (!divisions.includes(s.divisionId)) divisions.push(s.divisionId);
  });
  return divisions;
}

export function getCountries(masterDataResponse: IStore[]): ICountry[] {
  const countries: ICountry[] = [];
  masterDataResponse.forEach((store) => {
    if (countries.findIndex((country) => country.label === store.country) === -1) {
      countries.push({
        division: store.division,
        divisionId: store.divisionId,
        id: store.countryId,
        label: store.country,
      });
    }
  });
  return countries;
}

export function joinParams(params: Record<string, any>) {
  return Object.entries(params)
    .map(([k, v]) => k + "=" + v)
    .join("&");
}

export function capitalizeText(text?: string) {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function sliceText(text: string, max: number) {
  if (!text) return "";
  return text.length > max ? `${text.slice(0, max)}...` : text;
}

export function getUserFullName(user?: IManager) {
  const name = user?.name ? capitalizeText(user.name) : "";
  const lastname = user?.lastname ? capitalizeText(user.lastname) : "";

  return `${name} ${lastname}`;
}

export function checkOverflow(el: HTMLElement | null) {
  if (!el) return false;

  const curOverflow = el.style.overflow;
  if (!curOverflow || curOverflow === "visible") el.style.overflow = "hidden";

  const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
  el.style.overflow = curOverflow;

  return isOverflowing;
}

export function isValidErrorMessage(text: string) {
  return !text.includes("errors.") && !text.includes("notifications.");
}

export function getMRTLocalization(language: string) {
  switch (language) {
    case "en":
      return MRT_Localization_EN;
    case "fr":
      return MRT_Localization_FR;
    case "pt":
      return MRT_Localization_PT;
    default:
      return MRT_Localization_ES;
  }
}

export function getTimeFramesRange(timeFrames: ITimeFrameItem[]) {
  let start: Date | null = null;
  let end: Date | null = null;

  timeFrames.forEach((time) => {
    if (start === null || isBefore(new Date(time.startDay), start)) start = new Date(time.startDay);
    if (end === null || isAfter(new Date(time.endDay), end)) end = new Date(time.endDay);
  });

  if (start && end) return `${format(start, "HH:mm")} - ${format(end, "HH:mm")}`;
  else return "";
}

export function randomColor(colors: { [key: string]: string }) {
  var keys = Object.keys(colors);
  return colors[keys[(keys.length * Math.random()) << 0]];
}

/** compares */

export const compareStores = (a: IStore, b: IStore): number => {
  if (a.storeAcronym < b.storeAcronym) return -1;
  if (a.storeAcronym > b.storeAcronym) return 1;
  return 0;
};

export const compareObjectives = (a: IObjectiveItem, b: IObjectiveItem): number => {
  if (a.objectiveAchieved) return -1;
  if (b.objectiveAchieved) return 1;
  return 0;
};

export function compareNotesPriority(a: INoteItem, b: INoteItem) {
  if (a.priority && !b.priority) return -1;
  if (!a.priority && b.priority) return 1;
  return 0;
}

export function compareNotesDate(a: INoteItem, b: INoteItem) {
  const dateA = new Date(a.lastUpdateDate);
  const dateB = new Date(b.lastUpdateDate);
  if (isBefore(dateA, dateB)) return 1;
  if (!isBefore(dateA, dateB)) return -1;
  return 0;
}

export function compareSegmentsByHour(a: ISegmentItem, b: ISegmentItem) {
  if (parseInt(a.hour) < parseInt(b.hour)) return -1;
  if (parseInt(a.hour) > parseInt(b.hour)) return 1;
  return 0;
}

export function compareReviewHour(a: FormattedHourlyReview, b: FormattedHourlyReview) {
  if (a.item.reviewHour < b.item.reviewHour) return -1;
  if (a.item.reviewHour > b.item.reviewHour) return 1;
  return 0;
}

export function compareEmployess(a: IEmployeePositioning, b: IEmployeePositioning) {
  if (a.personName < b.personName) return -1;
  if (a.personName > b.personName) return 1;
  return 0;
}

export function compareLocations(a: IEmployeePositioning, b: IEmployeePositioning) {
  if (!a.category || !b.category) return 0;
  if (a.category < b.category) return -1;
  if (a.category > b.category) return 1;
  return 0;
}

export function compareVersions(a: IChangelogItem, b: IChangelogItem) {
  if (a.version < b.version) return 1;
  if (a.version > b.version) return -1;
  return 0;
}

export function compareTimeFrames(a: ITimeFrameItem, b: ITimeFrameItem) {
  const dateA = new Date(a.startDay);
  const dateB = new Date(b.startDay);
  if (isBefore(dateA, dateB)) return -1;
  if (!isBefore(dateA, dateB)) return 1;
  return 0;
}

/** descriptions */

export function getAreaDescription(area: IAreaType) {
  switch (i18n.language) {
    case "en":
      return area.description_en;
    case "pt":
      return area.description_pt;
    case "fr":
      return area.description_fr;
    default:
      return area.description_es;
  }
}

export function getScheduleDescription(item?: IScheduleItem) {
  if (!item) return "";
  switch (i18n.language) {
    case "en":
      return capitalizeText(item.shiftTypeDescription_en);
    case "pt":
      return capitalizeText(item.shiftTypeDescription_pt);
    case "fr":
      return capitalizeText(item.shiftTypeDescription_fr);
    default:
      return capitalizeText(item.shiftTypeDescription_es);
  }
}

export function getNoteShiftDescription(item: INoteItem) {
  switch (i18n.language) {
    case "en":
      return capitalizeText(item.shiftTypeDescription_en);
    case "pt":
      return capitalizeText(item.shiftTypeDescription_pt);
    case "fr":
      return capitalizeText(item.shiftTypeDescription_fr);
    default:
      return capitalizeText(item.shiftTypeDescription_es);
  }
}

export function getActivityTypeDescription(item?: IActivityTypeItem) {
  if (item)
    switch (i18n.language) {
      case "en":
        return capitalizeText(item.description_en);
      case "pt":
        return capitalizeText(item.description_pt);
      case "fr":
        return capitalizeText(item.description_fr);
      default:
        return capitalizeText(item.description_es);
    }
  else return "";
}

export function getCheckListDescription(item?: IActivityItem, isCustom?: boolean) {
  if (item) {
    if (isCustom) return capitalizeText(item.description);
    switch (i18n.language) {
      case "en":
        return capitalizeText(item.description_en);
      case "pt":
        return capitalizeText(item.description_pt);
      case "fr":
        return capitalizeText(item.description_fr);
      default:
        return capitalizeText(item.description_es);
    }
  } else return "";
}

export function getObjectiveTypeDescription(item?: IObjectiveTypeItem) {
  if (item)
    switch (i18n.language) {
      case "en":
        return capitalizeText(item.description_en);
      case "pt":
        return capitalizeText(item.description_pt);
      case "fr":
        return capitalizeText(item.description_fr);
      default:
        return capitalizeText(item.description_es);
    }
  else return "";
}

export function getObjectiveDescription(item?: IObjectiveItem, objective?: "type" | "item") {
  if (item) {
    if (objective === "type")
      switch (i18n.language) {
        case "en":
          return capitalizeText(item.objectiveTypeDescription_en);
        case "pt":
          return capitalizeText(item.objectiveTypeDescription_pt);
        case "fr":
          return capitalizeText(item.objectiveTypeDescription_fr);
        default:
          return capitalizeText(item.objectiveTypeDescription_es);
      }
    else if (objective === "item") {
      if (item.objectiveTypeId === "OT") return item.objectiveDescription;
      switch (i18n.language) {
        case "en":
          return capitalizeText(item.objectiveItemDescription_en);
        case "pt":
          return capitalizeText(item.objectiveItemDescription_pt);
        case "fr":
          return capitalizeText(item.objectiveItemDescription_fr);
        default:
          return capitalizeText(item.objectiveItemDescription_es);
      }
    }
  } else return "";
}

export function getHourlyReviewStepDescription(
  item?: IHourlyReviewStepsList,
  description?: string
) {
  if (item && description === "group") {
    switch (i18n.language) {
      case "en":
        return capitalizeText(item.groupDescription_en);
      case "pt":
        return capitalizeText(item.groupDescription_pt);
      case "fr":
        return capitalizeText(item.groupDescription_fr);
      default:
        return capitalizeText(item.groupDescription_es);
    }
  } else if (item && description === "focus") {
    switch (i18n.language) {
      case "en":
        return capitalizeText(item.focusDescription_en);
      case "pt":
        return capitalizeText(item.focusDescription_pt);
      case "fr":
        return capitalizeText(item.focusDescription_fr);
      default:
        return capitalizeText(item.focusDescription_es);
    }
  }
  return "";
}

export function HourlyReviewDescription(item?: IGenericDescription) {
  if (item)
    switch (i18n.language) {
      case "en":
        return capitalizeText(item.description_en);
      case "pt":
        return capitalizeText(item.description_pt);
      case "fr":
        return capitalizeText(item.description_fr);
      default:
        return capitalizeText(item.description_es);
    }
  else return "";
}

export function getChangelogDescription(item: IChangelogItem | IChangelogItemDescription) {
  switch (i18n.language) {
    case "en":
      return item.description_en;
    case "pt":
      return item.description_pt;
    case "fr":
      return item.description_fr;
    default:
      return item.description_es;
  }
}
