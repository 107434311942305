import { Action } from "@reduxjs/toolkit";
import { IEmployeeDetails, IEmployeeLocation, IEmployeePositioning } from "../../types";
import { ActionPayload } from "../actions";
import { RemoteResource } from "../utils";

export interface OrquestState {
  positioning: RemoteResource<IEmployeePositioning[]>;
  locations: RemoteResource<IEmployeeLocation[]>;
  categories: IEmployeeLocation[];
  employees: IEmployeeDetails[];
  isLoading: boolean;
  isUpdating: boolean;
}

export enum ActionType {
  getPositioningRequested = "orquest/get-positioning-requested",
  getPositioningDone = "orquest/get-positioning-done",
  getPositioningError = "orquest/get-positioning-error",

  updatePositioningRequested = "orquest/update-positioning-requested",
  updatePositioningDone = "orquest/update-positioning-done",
  updatePositioningError = "orquest/update-positioning-error",

  getLocationsRequested = "orquest/get-locations-requested",
  getLocationsDone = "orquest/get-locations-done",
  getLocationsError = "orquest/get-locations-error",
}

export interface PositioningPayload {
  positioning: IEmployeePositioning[];
}

export interface GetPositioningRequestedAction extends Action<ActionType.getPositioningRequested> {}

export interface GetPositioningDoneAction
  extends ActionPayload<ActionType.getPositioningDone, PositioningPayload> {}

export interface GetPositioningErrorAction extends Action<ActionType.getPositioningError> {}

export interface GetLocationsRequestedAction extends Action<ActionType.getLocationsRequested> {}

export interface UpdatePositioningPayload {
  positioning: IEmployeePositioning;
}

export interface UpdatePositioningRequestedAction
  extends ActionPayload<ActionType.updatePositioningRequested, UpdatePositioningPayload> {}
export interface UpdatePositioningDoneAction
  extends ActionPayload<ActionType.updatePositioningDone, UpdatePositioningPayload> {}

export interface UpdatePositioningErrorAction extends Action<ActionType.updatePositioningError> {}

export interface LocationsPayload {
  locations: IEmployeeLocation[];
}

export interface GetLocationsRequestedAction extends Action<ActionType.getLocationsRequested> {}

export interface GetLocationsDoneAction
  extends ActionPayload<ActionType.getLocationsDone, LocationsPayload> {}

export interface GetLocationsErrorAction extends Action<ActionType.getLocationsError> {}

export type Actions =
  | GetPositioningRequestedAction
  | GetPositioningDoneAction
  | GetPositioningErrorAction
  | UpdatePositioningRequestedAction
  | UpdatePositioningDoneAction
  | UpdatePositioningErrorAction
  | GetLocationsRequestedAction
  | GetLocationsDoneAction
  | GetLocationsErrorAction;
