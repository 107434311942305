import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  ListSubheader,
  Box,
  ListItem,
  SxProps,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { routes } from "../router/routes";
import HomeIcon from "@mui/icons-material/Home";
import AppsIcon from "@mui/icons-material/Apps";
import SalesDashboardIcon from "@mui/icons-material/MonetizationOn";
import EmployeePositioningIcon from "@mui/icons-material/Badge"; // Badge RecentActors ReduceCapacity
import ShiftOpeningIcon from "@mui/icons-material/AlarmOn";
import ShiftClosingIcon from "@mui/icons-material/AlarmOff";
import UserIcon from "@mui/icons-material/ManageAccounts";
import SchedulesIcon from "@mui/icons-material/PendingActions";
import AreasIcon from "@mui/icons-material/Fastfood";
import PreShiftIcon from "@mui/icons-material/EventAvailable";
import GoalsIcon from "@mui/icons-material/CrisisAlert";
import PreShiftTourIcon from "@mui/icons-material/Rule";
import ShiftTourIcon from "@mui/icons-material/History";
import PosShiftIcon from "@mui/icons-material/ContentPasteOff";
import SyncErrorIcon from "@mui/icons-material/SyncProblem";
import CustomCheklistIcon from "@mui/icons-material/NoteAltOutlined";
import { useSelector } from "react-redux";
import { isSidebarOpenSelector, navigateSelector } from "../redux/app/selectors";
import { appUserSelector, permissionsDataSelector } from "../redux/user/selectors";
import { useDispatch } from "react-redux";
import { setNavigateController, toggleSidebar } from "../redux/app/actions";
import { activityTypesDataSelector, storePlanIdSelector } from "../redux/storePlan/selectors";
import { getActivityTypeDescription } from "../helpers/functions";
import { ActivityType, AppPermissions, IActivitiesState } from "../types";
import {
  checklistsStateSelector,
  myActivitiesDataSelector,
} from "../redux/activities/checklists/selectors";
import { revStateSelector } from "../redux/activities/rev/selectors";

const syncFx: SxProps = {
  mt: 0.5,
  ml: -0.4,
  width: 27,
  height: 27,
  color: "error.main",
  "@keyframes syncGlow": {
    from: { color: "error.main" },
    to: { color: "error.light" },
  },
  animation: "syncGlow 0.5s ease-in-out infinite alternate",
};
interface Props {
  isAppOnline: boolean;
  isMobile?: boolean;
}

const SidebarNav = ({ isAppOnline, isMobile }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const permissions = useSelector(permissionsDataSelector);
  const isSidebarOpen = useSelector(isSidebarOpenSelector);
  const activityTypes = useSelector(activityTypesDataSelector);
  const myActivitiesData = useSelector(myActivitiesDataSelector);
  const user = useSelector(appUserSelector);
  const storePlanId = useSelector(storePlanIdSelector);
  const chkState = useSelector(checklistsStateSelector);
  const revState = useSelector(revStateSelector);
  const activitiesState: IActivitiesState = {
    syncRequired: chkState.syncRequired && revState.syncRequired,
    syncError: { ...chkState.syncError, ...revState.syncError },
  };
  const navigateController = useSelector(navigateSelector);
  const { syncRequired, syncError } = activitiesState;
  const isAdmin = user?.rol === "admin";
  const isManager = user?.rol === "manager" && storePlanId;

  const getIconColor = () => {
    if (theme.palette.mode === "dark") return "inherit";
    return "primary";
  };

  const adminItems = [
    {
      route: routes.main.managers.path,
      label: t("labels.managers"),
      icon: <UserIcon color={getIconColor()} />,
      enabled: true,
    },
    {
      route: routes.main.schedules.path,
      label: t("labels.schedules"),
      icon: <SchedulesIcon color={getIconColor()} />,
      enabled: true,
    },
    {
      route: routes.main.restaurant.path,
      label: t("labels.areas"),
      icon: <AreasIcon color={getIconColor()} />,
      enabled: true,
    },
    {
      route: routes.main.customChecklist.path,
      label: t("pages.customChecklist.title"),
      icon: <CustomCheklistIcon color={getIconColor()} />,
      enabled: true,
    },
  ];

  const storeItems = [
    {
      route: routes.main.opening.path,
      icon: <ShiftOpeningIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.OPENING]),
      activityType: ActivityType.OPENING,
    },
    {
      route: routes.main.closing.path,
      icon: <ShiftClosingIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.CLOSING]),
      activityType: ActivityType.CLOSING,
    },
    {
      route: routes.main.hourlyReview.path,
      icon: <ShiftTourIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.SHIFT_TOUR]),
      activityType: ActivityType.SHIFT_TOUR,
    },
  ];

  const shiftItems = [
    {
      route: routes.main.myActivities.path,
      icon: <CustomCheklistIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.CUSTOM]),
      activityType: ActivityType.CUSTOM,
      enabled: myActivitiesData.length > 0,
    },
    {
      route: routes.main.preShift.path,
      icon: <PreShiftIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.PRE_SHIFT]),
      activityType: ActivityType.PRE_SHIFT,
      enabled: true,
    },
    {
      route: routes.main.objectives.path,
      icon: <GoalsIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.OBJETIVES]),
      activityType: ActivityType.OBJETIVES,
      enabled: true,
    },
    {
      route: routes.main.checklist.path,
      icon: <PreShiftTourIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.CHECKLIST]),
      activityType: ActivityType.CHECKLIST,
      enabled: true,
    },
    {
      route: routes.main.posShift.path,
      icon: <PosShiftIcon color={getIconColor()} />,
      label: getActivityTypeDescription(activityTypes[ActivityType.POS_SHIFT]),
      activityType: ActivityType.POS_SHIFT,
      enabled: true,
    },
  ];

  const renderSubheader = (text: string) => {
    if (isSidebarOpen) {
      return <ListSubheader>{text}</ListSubheader>;
    } else {
      return (
        <Box sx={{ height: 48 }}>
          <Divider sx={{ marginX: 1.5, paddingTop: 2.5 }} />
        </Box>
      );
    }
  };

  const renderSyncError = (activityType: ActivityType) => {
    const error = syncRequired && syncError[activityType];
    if (isSidebarOpen && isAppOnline && error)
      return <SyncErrorIcon sx={{ ...syncFx, mt: 0.75 }} />;
    return null;
  };

  const renderAltSyncError = (activityType: ActivityType, icon: JSX.Element) => {
    const error = syncRequired && syncError[activityType];
    if (!isSidebarOpen && isAppOnline && error) return <SyncErrorIcon sx={syncFx} />;
    return icon;
  };

  const handleClick = (value: string) => {
    isMobile && dispatch(toggleSidebar());
    dispatch(
      setNavigateController({
        navigateController: {
          ...navigateController,
          path: value,
          currentPath: location.pathname,
        },
      })
    );
  };

  return (
    <>
      <List dense>
        <ListItemButton
          onClick={() =>
            handleClick(isManager ? routes.main.activitiesDashboard.path : routes.main.home.path)
          }
          selected={[routes.main.activitiesDashboard.path, routes.main.home.path].includes(
            location.pathname
          )}
        >
          <ListItemIcon sx={{ minWidth: 45 }}>
            <HomeIcon color={getIconColor()} />
          </ListItemIcon>
          <ListItemText primary={t("labels.shiftPlan")} />
        </ListItemButton>
        {isManager && permissions.includes(AppPermissions.SALES) && (
          <ListItemButton
            onClick={() => handleClick(routes.main.salesDashboard.path)}
            selected={[routes.main.salesDashboard.path].includes(location.pathname)}
          >
            <ListItemIcon sx={{ minWidth: 45 }}>
              <SalesDashboardIcon color={getIconColor()} />
            </ListItemIcon>
            <ListItemText primary={t("labels.salesDashboard")} />
          </ListItemButton>
        )}
        {isManager && permissions.includes(AppPermissions.POSITIONING) && (
          <ListItemButton
            onClick={() => handleClick(routes.main.employeePositioning.path)}
            selected={[routes.main.employeePositioning.path].includes(location.pathname)}
          >
            <ListItemIcon sx={{ minWidth: 45 }}>
              <EmployeePositioningIcon color={getIconColor()} />
            </ListItemIcon>
            <ListItemText primary={t("labels.positioning")} />
          </ListItemButton>
        )}
      </List>
      {isAdmin && (
        <List subheader={renderSubheader(t("labels.settings"))}>
          {adminItems.map(
            (i) =>
              i.enabled && (
                <ListItemButton
                  onClick={() => handleClick(i.route)}
                  selected={location.pathname.includes(i.route)}
                  key={i.route}
                >
                  <ListItemIcon sx={{ minWidth: 45 }}>{i.icon}</ListItemIcon>
                  <ListItemText primary={i.label} />
                </ListItemButton>
              )
          )}
        </List>
      )}
      {isManager && (
        <>
          <List dense subheader={renderSubheader(t("labels.store"))}>
            {storeItems.map((i) => (
              <ListItem
                key={i.route}
                secondaryAction={renderSyncError(i.activityType)}
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={() => handleClick(i.route)}
                  selected={location.pathname.includes(i.route)}
                >
                  <ListItemIcon sx={{ minWidth: 45 }}>
                    {renderAltSyncError(i.activityType, i.icon)}
                  </ListItemIcon>
                  <ListItemText primary={i.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <List dense subheader={renderSubheader(t("labels.shift"))}>
            {shiftItems.map(
              (i) =>
                i.enabled && (
                  <ListItem
                    key={i.route}
                    secondaryAction={renderSyncError(i.activityType)}
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleClick(i.route)}
                      selected={location.pathname.includes(i.route)}
                    >
                      <ListItemIcon sx={{ minWidth: 45 }}>
                        {renderAltSyncError(i.activityType, i.icon)}
                      </ListItemIcon>
                      <ListItemText primary={i.label} />
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </>
      )}
      <List dense subheader={<Divider sx={{ marginX: 1.5, paddingTop: 2.5 }} />}>
        <ListItemButton
          onClick={() => (window.location.href = process.env.REACT_APP_LAUNCHER_URL || "")}
        >
          <ListItemIcon sx={{ minWidth: 45 }}>
            <AppsIcon color={getIconColor()} />
          </ListItemIcon>
          <ListItemText primary="Flex Tiles" />
        </ListItemButton>
      </List>
      <div style={{ flexGrow: 1 }} />
    </>
  );
};

export default SidebarNav;
