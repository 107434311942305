import { compareVersions } from "../../helpers/functions";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../utils";
import { Actions, AppState, ActionType } from "./types";

const initialState: AppState = {
  isAppOnline: true,
  isSidebarOpen: false,
  syncRequired: false,
  syncFailed: false,
  alert: undefined,
  snackbar: undefined,
  navitateController: {},
  changelogsReport: initialRemoteResource([]),
};

const appReducer = (state: AppState = initialState, action: Actions): AppState => {
  switch (action.type) {
    case ActionType.setIsAppOnline:
      return {
        ...state,
        isAppOnline: action.payload.value,
      };
    case ActionType.toggleSidebar:
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    case ActionType.setSyncRequired:
      return {
        ...state,
        syncRequired: action.payload.value,
      };
    case ActionType.setSyncFailed:
      return {
        ...state,
        syncFailed: action.payload.value,
      };
    case ActionType.setAlert:
      return {
        ...state,
        alert: action.payload.alert,
      };
    case ActionType.consumeAlert:
      return {
        ...state,
        alert: undefined,
      };
    case ActionType.setSnackbar:
      return {
        ...state,
        snackbar: action.payload.alert,
      };
    case ActionType.consumeSnackbar:
      return {
        ...state,
        snackbar: undefined,
      };
    case ActionType.setNavigateController:
      return {
        ...state,
        navitateController: action.payload.navigateController,
      };

    case ActionType.getChangelogsReportRequested:
      return {
        ...state,
        changelogsReport: requestedRemoteResource([]),
      };
    case ActionType.getChangelogsReportDone:
      return {
        ...state,
        changelogsReport: doneRemoteResource(action.payload.changelogsReport.sort(compareVersions)),
      };
    case ActionType.getChangelogsReportError:
      return {
        ...state,
        changelogsReport: errorRemoteResource([]),
      };

    default:
      return state;
  }
};

export default appReducer;
