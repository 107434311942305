import { AlertColor, PaletteMode } from "@mui/material";
import { AxiosError } from "axios";

export interface IErrorResponse {
  message?: string;
  username?: string;
}
export interface IHttpRequestError {
  notified: boolean;
  code: number;
  data?: unknown;
  innerException?: AxiosError;
}

export interface IUserPreferences {
  language: string;
  paletteMode: PaletteMode;
  zoom: number;
}

export enum AlertCode {
  LOGIN_ERROR = "LOGIN_ERROR",
  INCORRECT_USERNAME_PASSWORD = "INCORRECT_USERNAME_PASSWORD",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  LOAD_FAILED = "LOAD_FAILED",
  INCOMPLETE_SCHEDULE = "INCOMPLETE_SCHEDULE",
  ERROR_USER_INDENTIFIER = "user_identifier_already_exists",
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  OFFLINE_WORKING = "OFFLINE_WORKING",

  LOADING_MASTERDATA = "LOADING_MASTERDATA",
  LOADING_STORE_PLAN = "LOADING_STORE_PLAN",
  LOADING_STORE_SETTINGS = "LOADING_STORE_SETTINGS",
  LOADING_ACTIVITIES = "LOADING_ACTIVITIES",

  MASTERDATA_ERROR = "MASTERDATA_ERROR",
  PERMISSIONS_ERROR = "PERMISSIONS_ERROR",
  AREAS_ERROR = "AREAS_ERROR",
  SCHEDULES_ERROR = "SCHEDULES_ERROR",
  MANAGERS_ERROR = "MANAGERS_ERROR",

  PRE_SUCCESS = "PRE_SUCCESS",
  POS_SUCCESS = "POS_SUCCESS",
  CHECKLIST_SUCCESS = "CHECKLIST_SUCCESS",
  OBJECTIVES_SUCCESS = "OBJECTIVES_SUCCESS",
  HOURLY_REVIEW_SUCCESS = "HOURLY_REVIEW_SUCCESS",
  NOTES_SUCCESS = "NOTES_SUCCESS",
  COMMENTS_SUCCESS = "COMMENTS_SUCCESS",

  STORE_PLAN_ERROR = "STORE_PLAN_ERROR",
  ACTIVITY_TYPES_ERROR = "ACTIVITY_TYPES_ERROR",
  OBJECTIVES_TYPES_ERROR = "OBJECTIVES_TYPES_ERROR",
  HOURLY_REVIEW_TYPES_ERROR = "HOURLY_REVIEW_TYPES_ERROR",
  PRE_ERROR = "PRE_ERROR",
  POS_ERROR = "POS_ERROR",
  CHECKLIST_ERROR = "CHECKLIST_ERROR",
  OBJECTIVES_ERROR = "OBJECTIVES_ERROR",
  HOURLY_REVIEW_ERROR = "HOURLY_REVIEW_ERROR",
  NOTES_ERROR = "NOTES_ERROR",
  COMMENTS_ERROR = "COMMENTS_ERROR",
  SALES_ERROR = "SALES_ERROR",
  POSITIONING_ERROR = "POSITIONING_ERROR",
}

export enum AppPermissions {
  APP = "MI_TURNO",
  SUPPORT = "SOPORTE",
  MANAGER = "MANAGER",
  SALES = "SALES_DASHBOARD",
  POSITIONING = "POSITIONING",
}

export enum ActivityType {
  OPENING = "OPN",
  CLOSING = "CLO",
  PRE_SHIFT = "PRE",
  OBJETIVES = "OBJ",
  SHIFT_TOUR = "REV",
  POS_SHIFT = "POS",
  CHECKLIST = "CHK",
  NOTES = "NTE",
  CUSTOM = "CUS",
}

export interface IActivitiesState {
  syncRequired: boolean;
  syncError: {
    [key: string]: boolean;
  };
}

export interface ISyncState {
  syncRequired: boolean;
  syncFailed: boolean;
}

export interface INavigateController {
  path?: string;
  currentPath?: string;
  hasChanges?: boolean;
  saveChanges?: boolean;
}

export interface IAlertMessage {
  code?: AlertCode;
  severity?: AlertColor;
  title?: string;
  message?: string;
  error?: IHttpRequestError;
  action?: string;
  actionLabel?: string;
}

export interface IStore {
  division: string;
  divisionId: string;
  country: string;
  countryId: number;
  storeId: number;
  storeAcronym: string;
}

export interface IMasterdata {
  divisions: string[];
  countries: ICountry[];
  stores: IStore[];
}

export interface ICountry {
  id: number;
  label: string;
  division: string;
  divisionId: string;
}

export interface IAppFilters {
  division?: string;
  country?: ICountry;
  store?: IStore;
  businessDate?: string;
  shiftTypeId?: string;
  storePlanId?: number;
  shift?: IScheduleItem;
}

export interface IQueryParams {
  division?: string;
  country?: string;
  storeAcronym?: string;
  store?: number;
  businessDate?: string;
  shiftTypeId?: string;
  storePlanId?: number;
  activityTypesId?: string;
  username?: string;
  fromDate?: string;
  toDate?: string;
  shiftStart?: string;
  shiftEnd?: string;
}

export interface IGenericDescription {
  description_es: string;
  description_en: string;
  description_fr: string;
  description_pt: string;
}
export interface IActivityTypeItem {
  id: string;
  description_es: string;
  description_en: string;
  description_fr: string;
  description_pt: string;
}

export interface IActivityTypes {
  [key: string]: IActivityTypeItem;
}

export interface IObjectiveTypeItem {
  id: string;
  objectivesTypesId: string;
  description_en: string;
  description_es: string;
  description_fr: string;
  description_pt: string;
  isAutomatic: boolean;
  invokedService: string;
}

export interface IObjectivesTypes {
  objectiveType: IObjectiveTypeItem[];
  objectiveItem: IObjectiveTypeItem[];
}

export interface IRequestedObjServices {
  dt: boolean;
  fc: boolean;
  mfy: boolean;
}
export interface ISoSDtReport {
  ot: number | null;
  lt: number | null;
  efec: number | null;
  oepe: number | null;
  totl: number | null;
  tcQty: number | null;
  tcHeld: number | null;
  hold: number | null;
  pfwdPercent: number | null;
  PSNT: number | null;
}

export interface ISoSFcReport {
  ot: number | null;
  efec: number | null;
  r2p: number | null;
  totl: number | null;
  tcQty: number | null;
}

export interface ISoSMfyReport {
  avgQty: number | null;
  tts: number | null;
  ovr50Qty: number | null;
  under25Qty: number | null;
  sn: number | null;
  tcQty: number | null;
}

export interface IAreaType {
  areaTypeId: string;
  store: string;
  description_es: string;
  description_en: string;
  description_pt: string;
  description_fr: string;
  areaEnabled: boolean;
}

export type ScheduleDay =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export interface IScheduleItem {
  weekday: string;
  shiftTypeId: string;
  shiftTypeDescription_es: string;
  shiftTypeDescription_en: string;
  shiftTypeDescription_pt: string;
  shiftTypeDescription_fr: string;
  shiftStart: string;
  shiftEnd: string;
  rushHours: number[];
  enabled: boolean;
}

export interface IShiftSchedules {
  [key: string]: IScheduleItem[];
}

export interface IScheduleRange {
  start: number | null;
  end: number | null;
}

export type UserRol = "manager" | "admin";

export interface IManager {
  identifier: number;
  name: string;
  lastname: string;
  active: boolean;
  username?: string;
  rol?: UserRol;
}

export interface IActivityItem {
  id: number;
  planActivityId: number;
  ActivityTypesId: string;
  customChecklistId: number;
  AreaTypeId: string;
  description_es: string;
  description_en: string;
  description_fr: string;
  description_pt: string;
  description: string;
  checked: boolean;
}

export interface ICustomChecklistItem {
  id?: number;
  title: string;
  description: string;
  enabled: boolean;
  checklist: Partial<IActivityItem>[];
}

export interface IStorePlan {
  storePlanId: number;
  OPN: IActivityItem[];
  CLO: IActivityItem[];
  PRE: IActivityItem[];
  CHK: IActivityItem[];
  POS: IActivityItem[];
  CUS: IActivityItem[];
  REV: IHourlyReviewItem[];
}

export interface IAppParameters {
  maxChecklists: number;
  maxActivities: number;
}

export interface IAreaChecklist {
  [key: string]: IActivityItem[];
}

export interface IStatProgress {
  total: number;
  pending: number;
  finished: number;
}

export interface ICommentItem {
  id: number;
  itemId: number;
  description: string;
  manager: IManager;
  lastUpdate: string;
  isEdited?: boolean;
}

export interface IObjectiveItem {
  id: number;
  objectiveTypeId: string;
  objectiveItemId: string;
  objectiveDescription: string;
  objectiveAchieved: boolean | null;
  objectiveTypeDescription_es: string;
  objectiveTypeDescription_en: string;
  objectiveTypeDescription_fr: string;
  objectiveTypeDescription_pt: string;
  objectiveItemDescription_es: string;
  objectiveItemDescription_en: string;
  objectiveItemDescription_fr: string;
  objectiveItemDescription_pt: string;
  comments: ICommentItem[];
  isAutomatic: boolean;
  invokedService: string | null;
  createdDate: string;
}

export type TimeStepState = "pending" | "completed" | "failed";

export interface IHourlyReviewItem {
  id: number;
  reviewHour: number;
  checked: boolean;
}

export interface IHourlyReviewStep {
  stepOrder: number;
  description_es: string;
  description_en: string;
  description_pt: string;
  description_fr: string;
  groupDescription_es: string;
  groupDescription_en: string;
  groupDescription_pt: string;
  groupDescription_fr: string;
  focusDescription_es: string;
  focusDescription_en: string;
  focusDescription_pt: string;
  focusDescription_fr: string;
}

export interface IHourlyReviewStepsList {
  stepOrder: number;
  groupDescription_es: string;
  groupDescription_en: string;
  groupDescription_pt: string;
  groupDescription_fr: string;
  focusDescription_es: string;
  focusDescription_en: string;
  focusDescription_pt: string;
  focusDescription_fr: string;
  descriptions: IGenericDescription[];
}

export interface IHRConfirmDescriptions {
  title: {
    description_es: string;
    description_en: string;
    description_pt: string;
    description_fr: string;
  };
  descriptions: {
    description_es: string;
    description_en: string;
    description_pt: string;
    description_fr: string;
  }[];
}

export interface IHourlyReviewTypes {
  steps: IHourlyReviewStepsList[];
  confirm: IHRConfirmDescriptions;
}

export interface IHourlyReviewTypesResponse {
  steps: IHourlyReviewStep[];
  confirm: IHRConfirmDescriptions;
}

export interface IHourlyReviewState {
  hour: number;
  confirmed: boolean;
  completed: boolean;
}

export type NoteState = "pending" | "active" | "done" | "delete";

export interface INoteItem {
  id: number;
  activityTypesId: string;
  shiftTypeId: string;
  shiftTypeDescription_es: string;
  shiftTypeDescription_en: string;
  shiftTypeDescription_pt: string;
  shiftTypeDescription_fr: string;
  createdDate: string;
  manager: IManager;
  lastUpdateDate: string;
  lastUpdateManager: IManager;
  title: string;
  description: string;
  private: boolean;
  priority: boolean;
  state: NoteState;
  comments: ICommentItem[];
}

export interface IUserConfig {
  config: string;
}

export interface INote {
  id: number;
  shift: string;
  title: string;
  description: string;
}

export interface INotification {
  type: string;
  count: number;
  description: string;
}

export interface ITimeStep {
  time: string;
  state: "pending" | "completed" | "failed";
}

export interface ITourStep {
  label: string;
  description: string;
}

export interface ITourShiftResponse {
  timeline: ITimeStep[];
  tours: ITourStep[];
  shiftTypeId: string;
}

export interface ISegmentItem {
  [key: string]: string | number;
  id: string;
  hour: string;
  segment: string;
  gcReal: number;
  gcProy: number;
  gcDif: number;
  hsReal: number;
  hsProy: number;
  ipReal: number;
  ipProy: number;
}

export interface ISegmentsMapper {
  [key: string]: ISegmentItem[];
}

export interface ICurrentSegmentsMapper {
  [key: string]: ISegmentItem;
}

export interface ISegments {
  [key: string]: ISegmentItem[];
  gcsReal: ISegmentItem[];
  gcsProy: ISegmentItem[];
  hourlyPositionsReal: ISegmentItem[];
  hourlyPositionsProy: ISegmentItem[];
}

export interface IDailyTotals {
  segments: ISegments;
  totals: ISegmentItem[];
  dailyTotals: ISegmentItem | null;
}

export interface IChartSerie {
  name: string;
  data: (number | null)[];
  type: string;
}

export interface IChartParams {
  seriesIndex: number;
  value: string | number;
  w: any;
}

export interface IFormattedSegments {
  segments: ISegmentsMapper;
  currentSegments: ICurrentSegmentsMapper;
  lineGcsSeries: IChartSerie[];
  mixedGcsSeries: IChartSerie[];
  mixedHsSeries: IChartSerie[];
  mixedIpSeries: IChartSerie[];
  categories: string[];
}

export interface ISalesHoursRequest {
  [key: string]: Partial<ISegmentItem>[];
}

export interface IChangelogItemDescription {
  description_es: string;
  description_en: string;
  description_pt: string;
  description_fr: string;
  type: "feature" | "fix";
}

export interface IChangelogItem {
  id: string;
  version: string;
  description_es: string;
  description_en: string;
  description_pt: string;
  description_fr: string;
  changelogs: IChangelogItemDescription[];
  links: string[];
}

export interface IEmployeeDetails {
  personId: string;
  personName: string;
}

export interface IEmployeeLocation {
  locationId: number;
  categoryId: number;
  name: string;
  shortName: string;
  category: string;
  color: string;
}

export interface ITimeFrameItem {
  frameId: number;
  startDay: string;
  endDay: string;
  location: IEmployeeLocation;
}

export interface IEmployeePositioning {
  personId: string;
  personName: string;
  businessDay: string;
  presence: {
    worked: boolean;
    timeFrames: ITimeFrameItem[];
  };
  // table props
  subRows?: IEmployeePositioning[];
  name?: string;
  categoryId?: number;
  category?: string;
  timeRange?: string;
  time?: number;
}
